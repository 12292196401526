<mat-form-field class="example-chip-list">
  <mat-label i18n="@@regionSelectorSelectedRegions">Ausgewählte Regionen</mat-label>
  <mat-chip-list #chipList aria-label="Regionen auswählen">
    <mat-chip *ngFor="let region of selectedRegions" [selectable]="true" [removable]="true" (removed)="remove(region)">
      {{region.description}} {{region.name}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input i18n-placeholder="@@regionSelectorSearchRegion" placeholder="Region suchen" #fruitInput [formControl]="fruitCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    <mat-hint *ngIf="maxRegions" [ngStyle]="{'color': selectedRegions.length >= maxRegions ? 'red' : 'rgba(0,0,0,.54)'}" [align]="'end'">{{selectedRegions.length}}/{{maxRegions}}</mat-hint>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let region of filteredRegions | async" [value]="region">
      {{region.description}} {{region.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<p *ngIf="maxRegions && selectedRegions.length > maxRegions" style="color:red;" i18n="@@regionSelectorMaximumRegions">Die maximale Anzahl an Regionen ist überschritten.</p>