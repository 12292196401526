<div class="outer">
  <mat-card>
    <div class="main">
      <div>
        <span>
          <a href="https://vis.uni.kn/">AG Keim - Datenanalyse und Visualisierung</a>
        </span>
      </div>
      <h2><span>Impressum, Kontakt und Haftungsausschluss</span></h2>
      <div class="content detail">
        <p class="bodytext">
          <p class="bodytext"><span id="docs-internal-guid-b691652f-7fff-bf44-4433-cd88af65c8d2"><span
                style="font-size: 11pt; font-family: Arial; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap; ">Die
                Universität Konstanz ist eine Körperschaft des öffentlichen Rechts. Sie wird durch ihre Rektorin, Prof.
                Dr.
                Kerstin Krieglstein, gesetzlich vertreten.</span></span>
          </p>
          <p class="bodytext"><b>Inhaltliche Verantwortung:</b>
            <br /><a href="mailto:dsgvo@dbvis.inf.uni-konstanz.de">webmaster@dbvis.inf.uni-konstanz.de</a>
          </p>
          <p class="bodytext"><b>Externe Links:</b>
          </p>
          <p class="bodytext"><span id="docs-internal-guid-da3720a3-7fff-9295-5f2e-aff30703fb59"><span
                style="font-size: 11pt; font-family: Arial; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap; ">Die
                Universität Konstanz ist als Inhaltsanbieter für die eigenen Inhalte, die sie zur Nutzung bereithält,
                nach
                den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise (externe Links)
                auf
                die von anderen Anbietern bereit gehaltenen Inhalte zu unterscheiden. Diese fremden Inhalte stammen
                nicht
                von der Universität Konstanz und spiegeln auch nicht die Meinung der Universität Konstanz wider, sondern
                dienen lediglich der Information. Die Universität Konstanz macht sich diese Inhalte nicht zu
                eigen.</span></span>
          </p>
          <p class="bodytext"><b>Hinweis zur Nutzung:</b>
          </p>
          <p class="bodytext"><span id="docs-internal-guid-20707a8c-7fff-e7fe-f52b-26a3b740b34c"><span
                style="font-size: 11pt; font-family: Arial; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap; ">Die
                AG Datenanalyse und Visualisierung der Universität Konstanz stellt diese Website unentgeltlich und frei
                zur Verfügung. Über einen Verwendungshinweis würden wir uns freuen. Die verwendeten Datenquellen wie im
                Hauptmenu beschrieben unterliegen gegebenenfalls datenschutzrechtlichen Beschränkungen ihrer
                Urheber.</span></span>
          </p>
          <p class="bodytext"><span id="docs-internal-guid-c8a1486e-7fff-bca6-13a8-1a49e72be396"><span
                style="font-size: 11pt; font-family: Arial; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap; ">Anfragen
                richten Sie bitte an </span></span><a
              href="mailto:dsgvo@dbvis.inf.uni-konstanz.de">webmaster@dbvis.inf.uni-konstanz.de</a></p>
          <p class="bodytext"><b>Datenschutz:</b>
          </p>
          <p class="bodytext"><span id="docs-internal-guid-503d0c0b-7fff-5f03-14be-483d1b10d92a"><span
                style="font-size: 11pt; font-family: Arial; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap; ">Datenschutzinformationen
                können hier abgerufen werden: <a
                  href="https://coronavis.dbvis.de/overview/gdpr">https://coronavis.dbvis.de/overview/gdpr</a></span></span>
          </p>




      </div>
      <aside class="content-right">
        <section class="box contact">
          <h2>Kontakt</h2>
          <p>Postanschrift
            <br /> AG Keim
            <br /> Prof. Dr. Daniel Keim
            <br /> Universität Konstanz
            <br /> 78457 Konstanz, Germany
            <br /> Tel.: +49 7531 88-3025
            <br /> Fax.: +49 7531 88-3268
            <br />
          </p>
        </section>
      </aside>
    </div>
  </mat-card>
</div>
