<h5 *ngIf="source === 'rki'" class="source" i18n="@@legendDatasourceRki">Datenquelle: 
  <a href="https://rki.de">Robert Koch Institut</a>&nbsp;<a href="http://www.govdata.de/dl-de/by-2-0">(dl-de/by-2-0)</a>&nbsp;<a href="https://services7.arcgis.com/mOBPykOjAyBO2ZKk/arcgis/rest/services/RKI_COVID19/FeatureServer">Datensatz</a>
</h5>

<h5 class="source" *ngIf="source === 'risklayer'" i18n="@@legendDatasourceRisklayer">Datenquelle:
  <a href="http://risklayer.com/">Risklayer GmbH</a> and
  <a href="https://www.cedim.kit.edu/"
    matTooltip="Center for Disaster Management and Risk Reduction Technology">CEDIM</a> at
  <a href="https://www.kit.edu/" matTooltip="Karlsruhe Institute of Technology">KIT</a> and the
  <a href="https://docs.google.com/spreadsheets/d/1wg-s4_Lz2Stil6spQEYFdZaBEp8nWW26gVyfHqvcl8s/htmlview#">Risklayer-CEDIM-Tagesspiegel
    SARS-CoV-2 Crowdsourcing Contributors</a>
</h5>

<div *ngIf="source === 'divi'">
  <div *ngIf="hideHint === false">
    <h5 class="source" i18n="@@legendDiviAddition">Persönliche Verfügbarkeits-Einschätzung der Krankenhäuser (inklusive
      Personalsituation)</h5>
  </div>

  <div style="margin-top: 5px;">
    <h5 class="source" i18n="@@legendDatasourceDivi">Datenquelle: <a href="https://intensivregister.de">DIVI
        Intensivregister</a></h5>
  </div>
</div>

<h5 *ngIf="source === 'zensus'" class="source" i18n="@@caseDodDataSourceZensus">Populationsdaten: <a href="https://www-genesis.destatis.de/genesis//online?operation=table&code=12411-0017&bypass=true&levelindex=1&levelid=1622017205004#abreadcrumb">Fortschreibung des Bevölkerungsstandes</a> (basierend auf <a href="https://www.zensus2011.de/DE/Home/home_node.html">Zensus 2011</a>), Stand 31.12.2019</h5>

<h5 *ngIf="source === 'survstat'" class="source" i18n="@@caseDodDataSourceSurvstat">Robert Koch Institut: SurvStat@RKI 2.0, <a href="https://survstat.rki.de">https://survstat.rki.de</a>, Abfragedatum: {{ now | date:'shortDate' }}</h5>

<h5 *ngIf="source === 'rki_incidences'" class="source" i18n="@@dataSourceRKIIncidences">Offizielle <a href="https://www.rki.de/inzidenzen">RKI Inzidenzwerte</a> zum <a href="https://www.bundesgesundheitsministerium.de/service/gesetze-und-verordnungen/guv-19-lp/4-bevschg-faq.html">4. Bevölkerungsschutzgesetz</a></h5>
