import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-introduction',
  templateUrl: './overview-introduction.component.html',
  styleUrls: ['./overview-introduction.component.less']
})
export class OverviewIntroductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
